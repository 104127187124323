<template>
  <div>
    <q-form ref="editForm">
      <c-card title="평가계획 기본정보" class="cardClassDetailForm">
        <template slot="card-detail">
          <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4" style="margin-top:5px;">
            <c-text
              :editable="editable"
              :disabled="true"
              label="평가명"
              name="assessmentName"
              v-model="assessPlan.assessmentName">
            </c-text>
          </div>
          <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
            <c-datepicker
              :editable="editable"
              :disabled="true"
              :range="true"
              label="평가기간"
              name="period"
              v-model="assessPlan.assessmentPeriod"
            />
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="editable"
              :disabled="true"
              codeGroupCd="RAM_TECHNIQUE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="ramTechniqueCd"
              label="평가구분-정기/수시/최초"
              v-model="assessPlan.ramTechniqueCd"
            ></c-select>
          </div>
          <div class="col-xs-6 col-sm-6 col-md-2 col-lg-2 col-xl-2">
            <c-select
              :editable="editable"
              :disabled="true"
              codeGroupCd="RAM_ASSESS_TYPE_CD"
              type="edit"
              itemText="codeName"
              itemValue="code"
              name="ramAssessTypeCd"
              label="평가구분-정기/수시/최초"
              v-model="assessPlan.ramAssessTypeCd"
            ></c-select>
          </div>
        </template>
      </c-card>
    </q-form>
  </div>
</template>

<script>
export default {
  name: 'riskPlanInfo',
  props: {
    assessPlan: {
      type: Object,
      default: () => ({
        ramRiskAssessmentPlanId: '',  // 평가 일련번호
        plantCd: '',  // 사업장 코드
        ramTechniqueCd: '',  // 평가기법 코드_HAZOP/KRAS/JSA/CHECKLIST
        ramAssessTypeCd: '',  // 평가구분_최초/정기/수시
        assessmentName: '',  // 평가명
        assessmentStartDate: '',  // 평가시작일
        assessmentEndDate: '',  // 평가종료일
        assessmentPeriod: [],
        assessmentYear: '',  // 평가년도
        ramStepCd: '',  // 평가 진행단계 코드
        assessmentManageDeptCd: '',  // 평가주관 부서 코드
        assessmentManageUserId: '',  // 평가주관 담당자 ID
        ramMatrixId: null,  // 위험메트릭스 번호
        sopMocId: '',  // MOC 일련번호
        remark: '',  // 상세내용
        workArea: '',  // 작업지역
        regDtStr: '',
        regUserId: '',  // 작성자 ID
        regUserName: '',  // 작성자 ID
        reviewUserId: '',  // 검토자 ID
        reviewDate: '',  // 검토일
        approvalUserId: '',  // 승인자 ID
        approvalDate: '',  // 승인일
        vendorCd: '',
        sopConstructionId: '',
        chgUserId: '',  // 수정자 ID
        teams: [], // 평가팀 목록
        occasions: [], // 수시 변경/추가 내역 목록
        conferences: [], // 회의 목록
        relatedDocuments: [], // 관련자료 목록
        processes: [], // 대상공정 목록
      }),
    },
    disabled: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      editable: true,
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      // code setting
      // list setting
    },
  }
};
</script>
